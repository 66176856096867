import React from 'react'
import { Masthead } from '@ta-interaktiv/react-masthead'
import { PortfolioTeasers } from '../portfolioTeasers'
import 'ta-semantic-ui/semantic/dist/components/reset.css'
import 'ta-semantic-ui/semantic/dist/components/site.css'
import 'ta-semantic-ui/semantic/dist/components/segment.css'
import 'ta-semantic-ui/semantic/dist/components/grid.css'
import 'ta-semantic-ui/semantic/dist/components/table.css'
import 'ta-semantic-ui/semantic/dist/components/header.css'
import 'ta-semantic-ui/semantic/dist/components/label.css'
import 'ta-semantic-ui/semantic/dist/components/divider.css'
import 'ta-semantic-ui/semantic/dist/components/image.css'
import 'styles/App.css'
import styles from './styles.module.scss'
import { ThemeSwitch } from '@ta-interaktiv/react-theme-switch'
import { Footer } from '@ta-interaktiv/react-footer'

const ADDITIONAL_SHARES = 69
const ARTICLE_ID = '704620471453'

export function AppComponent() {
  return (
    <div className='index'>
      <Masthead articleId={ARTICLE_ID} additionalShares={ADDITIONAL_SHARES} />
      <ThemeSwitch />

      <header className='ui vertical very fitted segment'>
        <div className='ui container'>
          <div className='ui header'>
            <h1>
              L'équipe interactif de la rédaction Tamedia fait du journalisme
              visuel et de données. Vous trouverez ici nos publications les plus
              récentes.
            </h1>
          </div>
        </div>
      </header>

      <main>
        <div className='ui vertical fitted segment'>
          <PortfolioTeasers />
        </div>
      </main>

      <Footer
        mailTo='interactif@tamedia.ch'
        locale='fr'
        additionalShares={ADDITIONAL_SHARES}
        invertedBottom
        articleId={ARTICLE_ID}
        hidePortfolioTeaser
        hideUserFeedback
        topHalfClassName='basic'
        credits={[
          [
            'Équipe interactif',
            `
          <span class='${styles.nobreak}'>
                          <a href='https://twitter.com/titusplattner'>
                            <i class='twitter icon'></i>Titus Plattner
                          </a>
                          (responsable)
                        </span>
                        <br />
                        <span class='${styles.nobreak}'>
              Georges Cabrera</span>,
                        <span class='${styles.nobreak}'><a href='https://twitter.com/f_giroud'>
                            <i class='twitter icon' ></i>Fanny Giroud</a>,</span>
                        <span class='${styles.nobreak}'><a href='https://twitter.com/palrogg'>
                            <i class='twitter icon' ></i>Paul Ronga</a>,</span>
                        <span class='${styles.nobreak}'><a href='https://twitter.com/rudamat'>
                            <i class='twitter icon' ></i>Mathieu Rudaz</a></span>
          `
          ]
        ]}
      />
    </div>
  )
}

AppComponent.defaultProps = {}
